import * as React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function ASMAlert(props) {
  const [open, setOpen] = React.useState(props.open);
  const withClose = props.withClose || false;
  const handleCloseAlert = props.handleCloseAlert;
  let test = props.open;
  const severity = props.severity || "info";
  const message = props.message || "Message";

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      {!withClose && <Snackbar open={test} anchorOrigin={{  vertical: 'bottom',
            horizontal: 'left', }} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
      }
      {withClose && <Snackbar anchorOrigin={{  vertical: 'bottom',
            horizontal: 'left', }} open={test} onClose={handleCloseAlert}>
        <Alert onClose={handleCloseAlert} severity={severity} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
      }
    </Stack>
  );
}
