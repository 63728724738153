import { Button, FormControl, Grid, Select } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Fragment, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import SuspenseLoader from 'src/components/SuspenseLoader';
import { CURRENT_LANG, CURRENT_USER, TOKEN_USER, TOKEN_USER_ORI } from 'src/constants';
import { User } from 'src/models/user.model';
import { authActions } from 'src/redux/actions';
import { useAppDispatch } from 'src/redux/hook';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { store } from 'src/redux/store';
import AzureAD, { AuthenticationState } from 'react-aad-msal';
import { Trans, useTranslation } from 'react-i18next';
import { authProvider } from 'src/content/Services/auth.provider';
import { authService } from 'src/content/Services/auth.service';

const HeaderWrapper = styled(Grid)(
  ({ theme }) => `
    height: ${theme.header.height};
    color: ${theme.header.textColor};
    padding: ${theme.spacing(0, 2)};
    right: 0;
    z-index: 5;
    background-color: ${theme.header.background};
    box-shadow: ${theme.header.boxShadow};
    position: fixed;
    justify-content: space-between;
    width: 100%;
`
);

const titleStyle = {
  color: '#2d2927',
  fontSize: '1.625rem',
  marginBottom: '0.5rem',
  fontFamily: 'inherit',
  fontWeight: 600,
  lineHeight: '1.5'
};

function Header() {


  let navigate = useNavigate();
  const [headerData, setHeaderData] = useState({
    username: '',
    role: ''
  });
  const { t, i18n } = useTranslation();
  const [userData, setUserData] = useState<User>(JSON.parse(localStorage.getItem(CURRENT_USER)));
  const [culture, isFrench] = useState(localStorage.getItem(CURRENT_LANG) === 'en' || false);
  const username = headerData.username + " (" + headerData.role + ")";
  const usernameTitle = userData?.firstName + " " + userData?.lastName + " (" + (headerData.role) + ")";
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const [userAccount, setUserAccount] = useState<User[]>([]);

  useEffect(() => {
    if (userData !== null) {
      setHeaderData({
        // username: userData.firstName + " " + userData.lastName.charAt(0) + ".",
        username: userData.firstName + " " + userData.lastName,
        role: userData.profiles
      });
    }
  }, []);


  const handleLogout = () => {
    dispatch(authActions.logout());
    localStorage.removeItem(TOKEN_USER);
    localStorage.removeItem(TOKEN_USER_ORI);
    localStorage.removeItem(CURRENT_USER);
    navigate('/', { replace: true });
  }

  // const [userCurrrentRole, setUserCurrrentRole] = useState(userData.id);

  // const handleChangeUserCurrrentRole = (event) => {
  //   setUserCurrrentRole(event.target.value);
  //   for (let i = 0; i < userAccount.length; i++) {
  //     if (userAccount[i].id === event.target.value) {
  //       setUserData(userAccount[i]);
  //       localStorage.setItem(CURRENT_USER, JSON.stringify(userAccount[i]));
  //       // if (userAccount[i].role.id > 2) {
  //       //   window.location.href = "/page/management";
  //       // } else {
  //       window.location.href = "/page/accueil";
  //       // }
  //       // break;
  //     }
  //   }
  // };

  const changeCulture = (x) => {
    setLoading(true);
    isFrench(x);
    const lan = x ? 'en' : 'fr'
    localStorage.setItem(CURRENT_LANG, lan);
    i18n.changeLanguage('en');
    const currentLocation = window.location.href;
    authService.updateLang({ idUser: userData.id, idLang: x ? 2 : 1 }).then(response => {
      if (response.data.success) {
        window.location.href = currentLocation;
        setLoading(false);
      } else {
        setLoading(false);
      }
    }).catch(err => {
      setLoading(false);
    });
  }


  return (
    <>
      {loading && (
        <><SuspenseLoader /></>
      )}
      <HeaderWrapper display="flex" alignItems="center"  >
        <Grid
          container
          direction="row"
          alignItems="center"
          style={{ textAlign: 'center' }}

        >
          <Grid item xs={2} component={Link}
            to="/page/accueil" >
            <img
              alt="Ligne vert"
              src="/static/images/logo/Tracé 41.svg"
            />
            <img
              alt="Ligne vert"
              src="/static/images/logo/Groupe 1.svg"
              style={{ margin: 10 }}
            />
            </Grid>
            <Grid item xs={2} >
            <p style={{
              textAlign: 'left',
              margin: 0,
              color: '#2d2927',
              fontSize: 12,
              marginTop: 21
            }}><Trans>question</Trans>
            </p>
            <p style={{
              textAlign: 'left',
              fontSize: 12,
              marginTop: -4
            }}>
            <Link
                to='#'
                onClick={(e) => {
                  window.location.href = "mailto:support-annonces@groupeastek.fr";
                  e.preventDefault();
                }}
              >
                support-annonces@groupeastek.fr
              </Link>
              </p>
          </Grid>
          <Grid item xs={3} >
            <h1 style={titleStyle}  >JOBS </h1>
          </Grid>
          <Grid item xs={1} ></Grid>
          <Grid item xs={1} >
            <a id="fr"
            onClick={() => changeCulture(false)}
            style={{
              paddingLeft: 0, paddingRight: 0,
              textDecoration: 'none',
              color: '#2d2927',
            }}>
              <span style={{
                marginLeft: '0.25rem',
                boxSizing: 'border-box',
                fontWeight: culture ? 'normal' : 900,
                margin: 4
              }}>FR</span></a>|
                <a id="en"
              onClick={() => changeCulture(true)}
              style={{
              paddingLeft: 0, paddingRight: 0,
              textDecoration: 'none',
              color: '#2d2927',
            }}><span style={{
              marginLeft: '0.25rem',
              boxSizing: 'border-box',
              fontWeight: culture ? 900 : 'normal',
              margin: 4
            }}>EN</span></a>
          </Grid>
          <Grid item xs={2} style={{paddingRight: 10  ,paddingLeft: 15}}>
            {userData !== null &&
              <>
                {userAccount.length < 2 &&
                  <h4
                    title={usernameTitle}
                    style={{
                      textAlign: 'end',
                      width: '120%',
                      color: "#000",
                      marginLeft: -15,
                      fontWeight: 700,
                       whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden'
                    }}                  
                    >
                    {username}
                  </h4>
                }
              </>
            }

          </Grid>
          <Grid item xs={1}>
            <AzureAD provider={authProvider} reduxStore={store}>
              {({ logout, authenticationState }) => {
                const isAuthenticated = authenticationState === AuthenticationState.Authenticated;
                const isUnauthenticated = authenticationState === AuthenticationState.Unauthenticated;
                if (isAuthenticated) {
                  return (
                    <Fragment>
                      <Button variant="contained"
                        color="secondary"
                        title={t('logoutLabel')}
                        onClick={logout}
                        style={{ marginLeft: 10, minWidth: 34, maxWidth: 34, minHeight: 34, maxHeight: 34, borderRadius: '50%' }}
                      >
                        <ExitToAppIcon style={{ fontSize: 28 }} />
                      </Button>
                    </Fragment>
                  );
                } else if (isUnauthenticated) {
                  return (
                    <Fragment>
                      <Button variant="contained"
                        color="secondary"
                        title={t('logoutLabel')}
                        onClick={handleLogout}
                        style={{ marginLeft: 10, minWidth: 34, maxWidth: 34, minHeight: 34, maxHeight: 34, borderRadius: '50%' }}
                      >
                        <ExitToAppIcon style={{ fontSize: 28 }} />
                      </Button>
                    </Fragment>
                  );
                }
              }}
            </AzureAD>
          </Grid>
        </Grid>

      </HeaderWrapper>
    </>
  );
}

export default Header;
