import { CURRENT_USER } from "src/constants";
import { AccessByRole, AccessPerPage } from "src/models/access.model";
import { User } from "src/models/user.model";

export function userHasAccessToPage(functionnality: string) {
  const userData: User = JSON.parse(localStorage.getItem(CURRENT_USER)) || null;
  if (userData === null) return false;
  
  if(functionnality === AccessPerPage.Standar){
    return true
  }
  if(functionnality === AccessPerPage.CDR){
    return (userData.idProfile != 3 && userData.idProfile != 8);
  }
  if (functionnality === AccessPerPage.menu) {
    return (userData.idProfile ===2 || userData.idProfile ===5);  // Admin, et Marketing
  }

  return false;
}

export function userHasPermission(functionnality: string) {
  const userData: User = JSON.parse(localStorage.getItem(CURRENT_USER)) || null;

  if (userData === null) return false;
  // Modele
  if (functionnality === AccessByRole.writeModele) {
    return (userData.idProfile != 2 && userData.idProfile != 5 );  // CDR 
  }
  if (functionnality === AccessByRole.UpdateTrigramme) {
    return (userData.idProfile == 1 || userData.idProfile == 2 || userData.idProfile == 5);  // Tout sauf direction et support
  }
  if (functionnality === AccessByRole.AccesMKT_Admin) {
    return (userData.idProfile == 2 || userData.idProfile == 5);  // Tout sauf direction et support
  }
  return false;
}