import { commonService } from "./common.service";

function login(email = null, token = null, pwd = null) {
  const body = {
    "LoginFromEmail": email,
    "IDToken": token,
    "Password": pwd
  };
  return commonService.post('Login/authentication', body);
}

const updateLang = (body) => {
  return commonService.putWithParam('User/updateLang', body);
};

export const authService = {
  login,
  updateLang
};