import { authActionsType, TOKEN_USER, CURRENT_USER, CURRENT_LANG, TOKEN_USER_ORI } from '../../constants';
import { alertActions } from '.';
import { authService } from 'src/content/Services/auth.service';

const login = (username = null, token = null) => {
  return dispatch => {
    authService.login(username, token)
      .then(
        response => {
          dispatch(success(response, token));
        },
        error => {
          dispatch(failure(error));
          dispatch(alertActions.errorbo(error));
        });
  };

  function success(res, token) {
    localStorage.setItem(TOKEN_USER, res.data.data.token);
    localStorage.setItem(TOKEN_USER_ORI, token);
    localStorage.setItem(CURRENT_LANG, res.data.data.idLang === 1 ? 'fr' : 'en');
    localStorage.setItem(CURRENT_USER, JSON.stringify(res.data.data));
    // if (res.data.data.role.id > 2) {
    //   window.location.href = "/page/management";
    // } else {
    window.location.href = "/page/accueil";
    // }
    return { type: authActionsType.LOGIN_SUCCESS, user: res.data.data }
  }
  function failure(error) { return { type: authActionsType.LOGIN_FAILURE, error } }
}

const loginas = (email = null, emailFrom = null) => {
  return dispatch => {
    authService.loginas(email, emailFrom)
      .then(
        response => {
          dispatch(success(response));
        },
        error => {
          dispatch(failure(error));
          dispatch(alertActions.errorbo(error));
        });
  };

  function success(res) {
    localStorage.setItem(TOKEN_USER, res.data.token);
    localStorage.setItem(CURRENT_USER, JSON.stringify(res.data.user));
    //history.push("/");
    return { type: authActionsType.LOGIN_SUCCESS, user: res.data }
  }

  function failure(error) { return { type: authActionsType.LOGIN_FAILURE, error } }
}

const backTo = () => {
  return dispatch => {
    authService.backTo()
      .then(
        response => {
          dispatch(success(response));
        },
        error => {
          dispatch(failure(error));
          dispatch(alertActions.errorbo(error));
        });
  };

  function success(res) {
    localStorage.setItem(TOKEN_USER, res.data.token);
    localStorage.setItem(CURRENT_USER, JSON.stringify(res.data.user));
    //history.push("/");
    return { type: authActionsType.LOGIN_SUCCESS, user: res.data }
  }

  function failure(error) { return { type: authActionsType.LOGIN_FAILURE, error } }
}

const logout = () => {
  return dispatch => {
    let lng = localStorage.getItem(CURRENT_LANG);
    localStorage.clear();
    localStorage.setItem(CURRENT_LANG, lng)
    dispatch(signout());
  }
  function signout() {
    return { type: authActionsType.LOGOUT }
  }
}

export const authActions = {
  login,
  loginas,
  backTo,
  logout
};