import { MsalAuthProvider, LoginType } from 'react-aad-msal';
const { REACT_APP_MFAAUTHORITY,
  REACT_APP_PROD_REDIRECT,
  REACT_APP_PROD_CLIENTID,
  REACT_APP_PP_REDIRECT,
  REACT_APP_DEV_CLIENTID,
  REACT_APP_DEV_REDIRECT,
  REACT_APP_PP_CLIENTID } = process.env;

const protocol = window.location.protocol;

const domain = window.location.hostname;

const port = window.location.port;



const url = `${protocol}//${domain}${port ? ":" + port : ""}`;
const clientId = (url === REACT_APP_PROD_REDIRECT) ? REACT_APP_PROD_CLIENTID : (url === REACT_APP_PP_REDIRECT) ? REACT_APP_PP_CLIENTID : REACT_APP_DEV_CLIENTID;
const redirectUri = (url === REACT_APP_PROD_REDIRECT) ? REACT_APP_PROD_REDIRECT : (url === REACT_APP_PP_REDIRECT) ? REACT_APP_PP_REDIRECT : REACT_APP_DEV_REDIRECT;

// Msal Configurations
const config = {
  auth: {
    authority: REACT_APP_MFAAUTHORITY,
    clientId: clientId,
    postLogoutRedirectUri: redirectUri,
    redirectUri: redirectUri,
    validateAuthority: true,
    // After being redirected to the "redirectUri" page, should user
    // be redirected back to the Url where their login originated from?
    navigateToLoginRequestUrl: false
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true
  }
};

// Authentication Parameters
const authenticationParameters = {
  scopes: ["openid"]
}

// Options
const options = {
  loginType: LoginType.Redirect,
  tokenRefreshUri: window.location.origin
}

export const authProvider = new MsalAuthProvider(config, authenticationParameters, options)