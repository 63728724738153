import { FC, useState, createContext } from 'react';
type ActionContext = { actionToggle: any; toggleAction: () => void };

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ActionContext = createContext<ActionContext>(
  {} as ActionContext
);

export const ActionProvider: FC = ({ children }) => {
  const [actionToggle, setActionToggle] = useState(false);
  const toggleAction = () => {
    setActionToggle(!actionToggle);
  };
  return (
    <ActionContext.Provider value={{ actionToggle, toggleAction }}>
      {children}
    </ActionContext.Provider>
  );
};
